<template>
  <div class="setting-container">
    <h2 class="setting-heading" v-if="data.title">{{ data.title }}</h2>
    <span class="settings-heading-divider"></span>
  </div>
  <div
    class="setting-container setting-wrapper flex flex-wrap items-center"
    :class="data.cssClasses"
  >
    <div class="setting-image-container">
      <img :src="data.image" alt="setting-image" v-if="data.image" />
    </div>
    <div
      class="setting-text-container"
      v-if="data.textData && data.textData.length"
    >
      <p v-for="(text, index) in data.textData" :key="index">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-container {
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  text-align: left;
}

.setting-wrapper {
  background: #f8f8f8;
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: Popins, sans-serif;
}

.setting-wrapper.bg-white {
  background: #fff;
}

.reverse-image {
  flex-direction: row-reverse;
}

.setting-heading {
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  color: #676a6c;
  text-align: left;
  margin-top: 90px;
  margin-bottom: 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
}

.settings-heading-divider {
  display: inline-block;
  border-bottom: 2px solid #17b191;
  width: 55px;
  margin-bottom: 70px;
}

.setting-image-container {
  width: 33.33%;
}

.setting-text-container {
  width: 66.66%;

  p {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .setting-text-container,
  .setting-image-container {
    width: 100%;
  }

  .setting-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    img {
      max-width: 170px;
    }
  }

  .setting-heading {
    margin-top: 60px;
  }

  .settings-heading-divider {
    margin-bottom: 40px;
  }

  .setting-heading,
  .setting-container {
    text-align: center;
  }

  .setting-wrapper {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
</style>