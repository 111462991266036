<template>
  <section class="homepage-settings">
    <SettingItem
      v-for="setting in settings"
      :key="setting.id"
      :data="setting"
    />
  </section>
</template>

<script>
import { inject, ref, onMounted } from "vue";
import SettingItem from "@/modules/settings/components/setting-item/SettingItem.vue";

export default {
  name: "HomepageSettings",

  components: {
    SettingItem,
  },

  setup() {
    const t = inject("t");

    const settings = ref([
      {
        id: 1,
        title: t("homepage.homepage-settings.text-1"),
        image: require("@/assets/configuration/advanced-security.png"),
        cssClasses: "",
        textData: [
          t("homepage.homepage-settings.text-7"),
          t("homepage.homepage-settings.text-8"),
          t("homepage.homepage-settings.text-9"),
        ],
      },
      {
        id: 2,
        title: t("homepage.homepage-settings.text-2"),
        image: require("@/assets/configuration/languages-settings.png"),
        cssClasses: "bg-white reverse-image",
        textData: [t("homepage.homepage-settings.text-10")],
      },
      {
        id: 3,
        title: t("homepage.homepage-settings.text-3"),
        image: require("@/assets/configuration/prising-settings.png"),
        cssClasses: "",
        textData: [t("homepage.homepage-settings.text-11")],
      },
      {
        id: 4,
        title: t("homepage.homepage-settings.text-4"),
        image: require("@/assets/configuration/deposit-settings.png"),
        cssClasses: "bg-white reverse-image",
        textData: [t("homepage.homepage-settings.text-12")],
      },
      {
        id: 5,
        title: t("homepage.homepage-settings.text-5"),
        image: require("@/assets/configuration/capabilities-settings.png"),
        cssClasses: "",
        textData: [
          t("homepage.homepage-settings.text-13"),
          t("homepage.homepage-settings.text-14"),
        ],
      },
      {
        id: 6,
        title: t("homepage.homepage-settings.text-6"),
        image: require("@/assets/configuration/communication-settings.png"),
        cssClasses: "bg-white reverse-image",
        textData: [t("homepage.homepage-settings.text-15")],
      },
    ]);

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      settings,
    };
  },
};
</script>
