<template>
  <main class="relative homepage">
    <Header />
    <Settings />
    <Footer />
  </main>
</template>

<script>
import Header from "@/modules/homepage/header/Header.vue";
import Settings from "@/modules/settings/Settings.vue";
import Footer from "@/modules/homepage/footer/Footer.vue";

export default {
  components: {
    Header,
    Settings,
    Footer,
  },
};
</script>

<style>
</style>